import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IconDetails} from '@core/interfaces/common/pages';
import {HelpersService} from '@core/utils/helpers.service';

@Component({
    selector: 'ngx-form-field-photo-dialog',
    templateUrl: './uploadPhotoDialog.component.html',
    styleUrls: ['./uploadPhotoDialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadPhotoDialogComponent extends Unsubscribable {
    // TODO: remove this component and use one of the existing file upload components?
    acceptedFileTypes: string[] = ['jpeg', 'jpg', 'png'];

    fileUploadForm: FormGroup = this.fb.group({
        fileList: this.fb.array([], Validators.required),
    });

    constructor(
        protected ref: NbDialogRef<UploadPhotoDialogComponent>,
        private fb: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private helpersService: HelpersService,
    ) {
        super();
    }
    onFileAdded(file) {
        const fr = new FileReader();
        fr.readAsDataURL(file.file);
        fr.onload = (e) => {
            file = {
                ...file,
                url: e.target.result,
            };
            this.fileList.push(this.createItem(file));
            this.changeDetectorRef.detectChanges();
        };
    }
    createItem = (file): FormGroup => {
        const size = (file.size / 1048576).toFixed(2) > '0.00' ? (file.size / 1048576).toFixed(2) : '0.01';
        return this.fb.group({
            name: file.name,
            status: file.status,
            fileFormat: file.fileFormat,
            dataOperationType: 'FULL_REFRESH',
            fileLoadType: 'SNAPSHOT',
            tableArray: [],
            progress: 0,
            size: this.helpersService.formatFileSize(file.size),
            file: file.file,
            url: file.url,
        });
    };
    get fileList() {
        return this.fileUploadForm.get('fileList') as FormArray;
    }
    getIcon(format: string): IconDetails {
        if (format.startsWith('.')) {
            format = format.substring(1);
        }

        format = format.toUpperCase();

        switch (format) {
            case 'PNG':
                return {
                    pack: 'engin',
                    icon: 'file-png-active',
                };
            case 'JPG':
                return {
                    pack: 'engin',
                    icon: 'file-jpg-active',
                };
            case 'JPEG':
                return {
                    pack: 'engin',
                    icon: 'file-jpeg-active',
                };
            default:
                return {
                    pack: 'eva',
                    icon: 'file-outline',
                };
        }
    }
    save() {
        this.ref.close({contents: this.fileList.value});
    }
    cancel() {
        this.ref.close();
    }
}
