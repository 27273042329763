import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {Observable} from 'rxjs';
import {APIResponse, Filter, FilterFieldOption} from '@core/interfaces/system/system-common';
import {FormResponseDto} from '@core/interfaces/engin/maintenace-planning/form-visualization';

export interface Form {
    assetNumber: number;
    createdDate: string;
    description: string;
    formCode: string;
    formVersion: number;
    id: number;
    name: string;
    status: string;
    userName: string;
    configured: boolean;
}

export abstract class FormsService {
    // Form content: basic content
    abstract createForm(name: string, description: string): Observable<APIResponse<Form>>;

    abstract toggleFormPublish(formId: number, isPublished: boolean): Observable<APIResponse<Form>>;

    abstract updateForm(formId: number, name: string, description: string): Observable<APIResponse<Form>>;

    abstract getInspectionFormDto(formId: number): Observable<APIResponse<FormResponseDto>>;

    // Form content: scope
    abstract getFormScope(formId: number): Observable<APIResponse<Filter[]>>;

    abstract setFormScope(formId: number, filters: Filter[]): Observable<APIResponse<any>>;

    abstract getFilterOptions(fieldKey: string, searchString: string): Observable<APIResponse<FilterFieldOption[]>>;

    // Form content: config
    abstract commitFormConfig(
        formId: number,
        isConfirmed: boolean,
        dataPrepToolId: number,
    ): Observable<APIResponse<Form>>;

    abstract downloadFormConfig(formId: number): Observable<APIResponse<any>>;

    // Other
    abstract getInspectionFormsDataSource(): Observable<DataSource>;

    abstract duplicateForm(formId: number): Observable<APIResponse<any>>;

    abstract deleteForm(formId: number): Observable<APIResponse<any>>;
}
