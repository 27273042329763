import {Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DefaultFilter} from '@mominsamir/ngx-smart-table';
import {debounceTime, distinctUntilChanged, filter, map, take, takeUntil} from 'rxjs/operators';
import {ReplaySubject, Subject} from 'rxjs';

@Component({
    template: `
        <div
            class="form-container"
            #buttonTarget
            (click)="handleClick()"
            ngxClickOutside
            (clickOutside)="handleClickOutside($event)"
        >
            <nb-select fullWidth multiple [formControl]="selectControl" [placeholder]="placeholder">
                <nb-select-label>
                    {{ isAllSelected ? 'All' : getLabels(selectControl.value) }}
                </nb-select-label>
                <nb-option [value]="''" class="search" (click)="handleOptionClick($event, null)">
                    <div class="search-overlay" *ngIf="isLoading" [nbSpinner]="isLoading"></div>
                    <nb-form-field>
                        <input
                            #filterInput
                            nbInput
                            fullWidth
                            [formControl]="filterControl"
                            (keydown.space)="$event.stopImmediatePropagation()"
                            placeholder="Search..."
                        />
                        <button nbSuffix nbButton ghost (click)="this.filterControl.reset()">
                            <nb-icon [icon]="'close'" pack="eva" [attr.aria-label]="'clear search field'"></nb-icon>
                        </button>
                    </nb-form-field>
                </nb-option>
                <nb-option
                    *ngIf="options.length && options.length === (filteredOptions$ | async).length && !dataFunction"
                    [value]="0"
                    (click)="toggleSelectAll()"
                >
                    Select All
                </nb-option>
                <nb-option
                    *ngFor="let option of filteredOptions$ | async"
                    [value]="option.value"
                    (click)="handleOptionClick($event, option)"
                >
                    {{ option.label }}
                </nb-option>
            </nb-select>
            <span class="amount" *ngIf="selectControl?.value?.length">
                {{ getLengthSelected(selectControl.value) }}
            </span>
        </div>
    `,
    styleUrls: ['./multi-select-with-search-filter.component.scss'],
})
export class MultiSelectWithSearchFilterComponent extends DefaultFilter implements OnInit, OnChanges, OnDestroy {
    @ViewChild('filterInput') filterInput: ElementRef;
    @ViewChild('buttonTarget') buttonTarget: any;

    delay = 0;
    searchDelay = 200;
    options: {value: string; label: string}[];
    placeholder: string;
    dataFunction: (fieldKey: string, searchString: string) => any;
    fieldName: string;

    selectControl: FormControl = new FormControl([]);
    filterControl: FormControl = new FormControl();
    filteredOptions$: ReplaySubject<{value: string; label: string}[]> = new ReplaySubject(1);

    isAllSelected: boolean = false;
    isOpen = false;
    isLoading = false;

    selectedOptions = [];

    _onDestroy = new Subject();

    constructor() {
        super();
    }

    ngOnInit() {
        this.selectControl.valueChanges
            .pipe(
                takeUntil(this._onDestroy),
                distinctUntilChanged(),
                filter((value) => {
                    //when focusing on search input checkbox selects
                    //this removes search-input's value from this.selectControl
                    // and stops the process of subscription to this event
                    let isSearch = value.includes('');
                    if (isSearch) {
                        let _value = value.filter((item) => item !== '');
                        this.selectControl.setValue(_value, {emitEvent: false});
                    }

                    return !isSearch;
                }),
                map((value: any[]) => {
                    if (value && value.length === this.options.length && !value.includes(0)) {
                        let _value = [...value, 0];
                        this.isAllSelected = true;
                        this.selectControl.setValue(_value, {emitEvent: false});
                        return _value;
                    } else if (value && value.length !== this.options.length + 1) {
                        let _value = value.filter((item) => item !== 0);
                        this.isAllSelected = false;
                        this.selectControl.setValue(_value, {emitEvent: false});
                        return _value;
                    }
                }),
                debounceTime(this.delay),
            )
            .subscribe((value: any[]) => {
                if (!value || !value.length) {
                    this.query = '';
                } else if (this.selectControl.status === 'VALID') {
                    let result = '';
                    value.forEach((item) => {
                        if (item) {
                            if (result === '') result += `${item}`;
                            else result += `|${item}`;
                        }
                    });

                    this.query = result;
                }
            });

        this.options = this.column.filter.config?.options || [];
        this.dataFunction = this.column.filter.config?.dataFunction || null;
        this.fieldName = this.column.filter.config?.fieldName || '';
        this.placeholder = this.column.filter.config?.placeholder || 'Select...';

        this.filterControl.valueChanges
            .pipe(takeUntil(this._onDestroy), distinctUntilChanged(), debounceTime(this.searchDelay))
            .subscribe((value: string) => {
                this.selectControl.reset([]);
                this.filterOptions();
                this.filterInput.nativeElement.focus();
            });

        if (!this.dataFunction) {
            this.filteredOptions$.next(this.options);
        } else {
            this.isLoading = true;
            this.dataFunction(this.fieldName, '')
                .pipe(take(1))
                .subscribe((data) => {
                    this.options = data.response.data.map((item) => {
                        return {value: item.key, label: item.name};
                    });
                    this.filteredOptions$.next(
                        data.response.data.map((item) => {
                            return {value: item.key, label: item.name};
                        }),
                    );
                    this.isLoading = false;
                });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.query) {
            this.query = changes.query.currentValue;
            this.selectControl.setValue(this.selectControl.value);
        }
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    handleClick(): void {
        if (this.isOpen) {
            setTimeout(() => {
                this.setFilter();
            }, 100);
        }
        this.isOpen = !this.isOpen;
    }

    handleClickOutside(event: any) {
        if (!this.buttonTarget.nativeElement.contains(event as Element)) {
            if (this.isOpen) {
                setTimeout(() => {
                    this.setFilter();
                }, 100);
            }
            this.isOpen = false;
        }
    }

    handleOptionClick(event, option): void {
        event.stopPropagation();
        if (!option) {
            return;
        }
        if (this.selectControl.value.includes(option.label)) {
            this.selectedOptions.push(option);
        } else {
            this.selectedOptions = this.selectedOptions.filter((option) => option.value !== option.value);
        }
    }

    toggleSelectAll() {
        if (this.selectControl.value.length >= this.options.length) {
            this.selectControl.reset([]);
            this.isAllSelected = false;
        } else {
            let _options = this.options.reduce((acc, curr) => {
                return [...acc, curr.value];
            }, []);
            this.selectControl.setValue([..._options, 0]);
            this.isAllSelected = true;
        }
    }

    protected filterOptions() {
        if (!this.options) {
            return;
        }

        let search = this.filterControl.value;
        if (!search) {
            if (!this.dataFunction) {
                this.filteredOptions$.next(this.createOptionsArray(this.selectedOptions, this.options));
                this.selectControl.setValue(this.selectedOptions.map((option) => option.value));
            } else {
                this.isLoading = true;
                this.dataFunction(this.fieldName, '')
                    .pipe(take(1))
                    .subscribe((data) => {
                        this.options = data.response.data.map((item) => {
                            return {value: item.key, label: item.name};
                        });
                        this.filteredOptions$.next(this.createOptionsArray(this.selectedOptions, this.options));
                        this.selectControl.setValue(this.selectedOptions.map((option) => option.value));
                        this.isLoading = false;
                    });
            }

            return;
        } else {
            search = search.toLowerCase();
        }

        if (!this.dataFunction) {
            this.filteredOptions$.next(
                this.createOptionsArray(
                    this.selectedOptions,
                    this.options.filter((opt) => opt.label.toLowerCase().indexOf(search) > -1),
                ),
            );
            this.selectControl.setValue(this.selectedOptions.map((option) => option.value));
        } else {
            this.isLoading = true;
            this.dataFunction(this.fieldName, search)
                .pipe(take(1))
                .subscribe((data) => {
                    this.options = data.response.data.map((item) => {
                        return {value: item.key, label: item.name};
                    });
                    this.filteredOptions$.next(this.createOptionsArray(this.selectedOptions, this.options));
                    this.selectControl.setValue(this.selectedOptions.map((option) => option.value));
                    this.isLoading = false;
                });
        }
    }

    createOptionsArray(selectedOptions, options): any[] {
        const mergedArray = [...selectedOptions, ...options];
        const result = mergedArray.filter((obj, index) => {
            return mergedArray.findIndex((dupObj) => JSON.stringify(dupObj) === JSON.stringify(obj)) === index;
        });
        return result;
    }

    getLabels(values: string[]) {
        let _labels = values?.reduce((acc, curr) => {
            if (acc !== '') acc += ', ';
            return (acc += this.options.find((option) => option.value === curr)?.label);
        }, '');
        return _labels || '';
    }

    // Get length, but without the 'All' option counted as an item
    getLengthSelected(options: any[]) {
        return options.filter((o) => o != 0 && o != '' && o != 'All').length;
    }
}
