import {Injectable} from '@angular/core';
import {
    ChangeHistoryDto,
    InspectionResultCorrectionDto,
    InspectionResultDto,
    InspectionResultReviewDto,
    MaintenancePlanningService,
} from '@core/interfaces/engin/maintenace-planning/maintenance-planning';
import {MaintenancePlanningApi} from '@core/backend/engin/api/maintenace-planning/maintenance-planning.api';
import {Observable} from 'rxjs';
import {APIResponse, Filter, FilterFieldOption, PresignedURL} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {InspectionResultFullDto} from '@core/interfaces/engin/maintenace-planning/form-visualization';

@Injectable()
export class MaintenancePlanningServiceImpl extends MaintenancePlanningService {
    constructor(private api: MaintenancePlanningApi) {
        super();
    }

    listDto(): Observable<DataSource> {
        return this.api.listDto();
    }

    findOneDto(resultId: number): Observable<InspectionResultDto> {
        return this.api.findOneDto(resultId);
    }

    findOneFullDto(resultId: number): Observable<InspectionResultFullDto> {
        return this.api.findOneFullDto(resultId);
    }

    getChangeHistory(resultId: number): Observable<ChangeHistoryDto[]> {
        return this.api.getChangeHistory(resultId);
    }

    submitReview(resultId: number, req: InspectionResultReviewDto): Observable<any> {
        return this.api.submitReview(resultId, req);
    }

    submitCorrection(resultId: number, req: InspectionResultCorrectionDto): Observable<InspectionResultFullDto> {
        return this.api.submitCorrection(resultId, req);
    }

    listDtoCsv(filterParams: Filter[]): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.listDtoCsv(filterParams);
    }

    findLatestInspectionResult(resultId: number): Observable<APIResponse<InspectionResultDto>> {
        return this.api.findLatestInspectionResult(resultId);
    }

    getFilterOptions(fieldKey: string, searchString: string): Observable<FilterFieldOption[]> {
        return this.api.getFilterOptions(fieldKey, searchString);
    }
    getPresignedUrl(fileName: string, fileFormat: string): Observable<APIResponse<PresignedURL>> {
        return this.api.getPresignedUrl(fileName, fileFormat);
    }
    uploadToPresignedUrl(url: string, fileData: File): Observable<any> {
        return this.api.upload(`${url}`, fileData);
    }
}
