import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {map, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {FormSection} from '@core/interfaces/engin/maintenace-planning/form-visualization';

@Component({
    selector: 'ngx-form-section',
    templateUrl: './form-section.component.html',
    styleUrls: ['./form-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionComponent extends Unsubscribable implements OnInit, OnChanges {
    @Input() sections: FormSection[];
    @Input() continueEvent: number;

    @Output() selectionChanged = new EventEmitter<{sectionId: number; subsectionId: number}>();
    form: FormGroup = this.fb.group({
        sectionId: this.fb.control(-1),
        subsectionId: this.fb.control(-1),
    });

    constructor(private fb: FormBuilder) {
        super();
    }

    ngOnInit(): void {
        this.form.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                map((value) => {
                    this.selectionChanged.emit(this.form.value);
                }),
            )
            .subscribe();

        // Open first section/subsection by default
        if (this.form.value.sectionId === -1 && this.form.value.subsectionId === -1) {
            this.defaultFistSections();
        }
    }

    defaultFistSections() {
        this.form.setValue({
            sectionId: this.sections[0].id,
            subsectionId: this.sections[0].sections[0]?.id || -1,
        });
    }

    selectSection(sectionId, subsectionId): void {
        this.form.setValue({
            sectionId: sectionId,
            subsectionId: subsectionId,
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const lastSection = this.sections[this.sections.length - 1];
        const lastSubSection = lastSection.sections[lastSection.sections.length - 1];
        const {sectionId, subsectionId} = this.form.value;
        if (!(sectionId === lastSection.id && subsectionId === lastSubSection.id)) {
            const currentSection = this.sections.find((item) => item.id === sectionId);
            const currentSectionIndex = this.sections.findIndex((item) => item.id === sectionId);
            const currentSubSection = currentSection?.sections;
            const currentSubSectionIndex = currentSubSection?.findIndex((item) => item.id === subsectionId);
            const nextSubSectionIndex =
                currentSubSection?.findIndex((item) => item.id === subsectionId + 1) === -1
                    ? currentSubSectionIndex + 1
                    : currentSubSection?.findIndex((item) => item.id === subsectionId + 1);
            if (this.form.value.sectionId !== -1 && this.form.value.subsectionId !== -1) {
                //children section selected
                if (currentSubSection.length - 1 > currentSubSectionIndex) {
                    this.form.setValue({
                        sectionId: currentSection.id,
                        subsectionId: currentSubSection[nextSubSectionIndex].id,
                    });
                } else {
                    if (this.sections.length - 1 >= currentSectionIndex) {
                        this.form.setValue({
                            sectionId: this.sections[currentSectionIndex + 1].id,
                            subsectionId: this.sections[currentSectionIndex + 1].sections[0]?.id,
                        });
                    }
                }
            } else if (this.form.value.sectionId !== -1 && this.form.value.subsectionId === -1) {
                // parent section selected
                if (this.sections[currentSectionIndex + 1]) {
                    this.form.setValue({
                        sectionId: this.sections[currentSectionIndex + 1].id,
                        subsectionId: -1,
                    });
                }
            }
        }
    }
}
