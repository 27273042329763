<ng-container *ngIf="currentSection">
    <nb-card class="main-content-container">
        <!-- Current section: header -->
        <nb-card-header>{{ currentSection.orderDescription }}: {{ currentSection.label }}</nb-card-header>

        <!-- Current section: body -->
        <nb-card-body>
            <!-- Render any number of nested subsections -->
            <ng-container *ngIf="currentSection?.sections?.length > 0">
                <ng-container
                    *ngTemplateOutlet="templateSections; context: {$implicit: currentSection.sections}"
                ></ng-container>
            </ng-container>

            <!-- Fields -->
            <ng-container *ngIf="currentSection?.fields?.length > 0">
                <ng-container
                    *ngTemplateOutlet="templateFields; context: {$implicit: currentSection.fields}"
                ></ng-container>
            </ng-container>
        </nb-card-body>
    </nb-card>
</ng-container>

<!-- Template for nested sub-sections -->
<ng-template #templateSections let-sections>
    <nb-accordion *ngFor="let section of sections">
        <nb-accordion-item [expanded]="true">
            <nb-accordion-item-header>{{ section.orderDescription }}: {{ section.label }}</nb-accordion-item-header>

            <nb-accordion-item-body>
                <!-- Further nested subsections -->
                <ng-container *ngIf="section?.sections?.length > 0">
                    <ng-container
                        *ngTemplateOutlet="templateSections; context: {$implicit: section.sections}"
                    ></ng-container>
                </ng-container>

                <!-- Fields -->
                <ng-container *ngIf="section?.fields?.length > 0">
                    <ng-container
                        *ngTemplateOutlet="templateFields; context: {$implicit: section.fields}"
                    ></ng-container>
                </ng-container>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
</ng-template>

<!-- Template for fields -->
<ng-template #templateFields let-fields>
    <ng-container [ngSwitch]="showPreviousValues">
        <!-- Split width between "previous" and "new" -->
        <ng-template [ngSwitchCase]="true">
            <nb-card class="value-cards">
                <nb-card-body class="no-padding">
                    <div class="flex-row">
                        <ngx-form-cell
                            class="full-width"
                            [cellType]="FormCellType.PREVIOUS"
                            [viewMode]="getViewModeForPrevious()"
                            [fields]="fields"
                            [results]="getFormResultsByType(FormCellType.PREVIOUS)"
                            [checkValidation]="checkValidation"
                        ></ngx-form-cell>
                        <ngx-form-cell
                            [ngClass]="{
                                'view-mode': (pageMode | async) === FormMode.VIEW,
                                'edit-mode': (pageMode | async) === FormMode.EDIT
                            }"
                            class="full-width"
                            [cellType]="FormCellType.CURRENT"
                            [viewMode]="getViewModeForCurrent()"
                            [fields]="fields"
                            [results]="getFormResultsByType(FormCellType.CURRENT)"
                            (formFieldChangeEvent)="formFieldChangeEvent.emit($event)"
                            [fieldResultForm]="fieldResultForm"
                            [checkValidation]="checkValidation"
                            [s3service]="s3service"
                        ></ngx-form-cell>
                    </div>
                </nb-card-body>
            </nb-card>
        </ng-template>

        <!-- Full width for "new" or "current" -->
        <ng-template [ngSwitchCase]="false">
            <ngx-form-cell
                class="full-width"
                [cellType]="getCellTypeNoPrevious()"
                [viewMode]="viewMode"
                [fields]="fields"
                [results]="getFormResultsByType(getCellTypeNoPrevious())"
            ></ngx-form-cell>
        </ng-template>
    </ng-container>
</ng-template>
