import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {
    FormCellType,
    FormField,
    FormFieldOption,
    FormViewModeType,
    SelectFieldOptionResultDto,
} from '@core/interfaces/engin/maintenace-planning/form-visualization';
import {FormFieldBaseComponent} from '@theme/components/form/cells/base/form-field-base.component';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';

interface Option extends SelectFieldOptionResultDto {
    label: string;
    order: number;
    selected: boolean;
}

@Component({
    selector: 'ngx-form-field-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss', '../base/form-field-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent extends FormFieldBaseComponent<SelectFieldOptionResultDto[]> implements OnInit {
    @Input() field: FormField;
    @Input() required: boolean;
    @Input() viewMode: FormViewModeType;
    @Input() cellType: FormCellType;
    @Input() fieldResultForm: FormGroup;

    options: Option[] = [];
    @Input() checkValidation: Observable<boolean> = new BehaviorSubject<boolean>(false);
    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        // Set options
        this.options = this.field.options.map((option: FormFieldOption) => {
            return {
                fieldId: this.field.id,
                optionType: this.field.options[0].optionType,
                optionId: option.id,
                label: option.optionLabel,
                order: option.order,
                selected: !(this.result?.find((o) => o.optionId == option.id) == undefined),
            };
        });
        this.genericInit();
        this.checkValidation
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((value) => !!value),
                map((_) => {
                    if (this.fieldForm?.value.length === 0 && this.required) {
                        this.fieldForm?.markAsTouched();
                        this.fieldForm?.setErrors({required: true});
                        this.cd.detectChanges();
                    }
                }),
            )
            .subscribe();
    }

    public selectedOption(pressedOption: Option): void {
        // Toggle selection of the current option, keeping the remainder unchanged.
        // There is no way this works right?
        pressedOption.selected = !pressedOption.selected;
        /*this.options.map(o => {
            return {
                ...o,
                selected: o.optionId == pressedOption.optionId ? !pressedOption.selected : o.selected,
            }
        });*/

        // This component does not trigger fieldForm.valueChanges so perform this manually
        const newValue: SelectFieldOptionResultDto[] = this.applyValueChange(this.options.filter((o) => o.selected));

        if (this.validate(newValue)) {
            this.fieldForm.setErrors(null);
            this.emitEvent(newValue);
        } else {
            this.fieldForm.setErrors({required: true});
        }
    }

    public emptyOptions(): boolean {
        return this.options?.length == 0;
    }

    /*
     * Implement abstract methods
     */
    validate(value: SelectFieldOptionResultDto[]): boolean {
        if (this.required && (value == null || value.length == 0)) {
            return false;
        }

        return true;
    }

    get fieldForm() {
        return this.fieldResultForm?.get(this.field.id + '') as FormArray;
    }

    applyValueChange(items: Option[]): SelectFieldOptionResultDto[] {
        if (items == null) return null;
        return items as SelectFieldOptionResultDto[];
    }

    getFormValue(): any {
        return this.options?.filter((o) => o.selected) || null;
    }
    getPreviousValue(): string {
        const selectedOptions = this.options.filter((item) => item.selected);
        const result = [];
        selectedOptions.forEach((item) => [result.push(item.label)]);
        return result.length > 0 ? result.join(',') : '-';
    }
}
