<nb-card [nbSpinner]="!scopeLoaded || isLoading" class="edit-dialog">
    <nb-card-header>
        <div>
            <p class="primary-header">Scope</p>
            <p class="sub-header">Filter all the assets that will be inspected with this form</p>
        </div>
    </nb-card-header>

    <nb-card-body>
        <b>Number of assets: {{ assetNumber }}</b>
        <div class="filter-wrapper" *ngIf="(genericFilters$ | async)?.length">
            <ngx-generic-filter
                *ngIf="scopeLoaded"
                (updateOptions)="getOptionsDynamically($event)"
                (valueChange)="addFilter($event)"
                [filterFields]="genericFilters$ | async"
                [filtersDynamicOptions]="filtersDynamicOptions$ | async"
                [selectedFilterFields]="preselectedFilters$ | async"
            ></ngx-generic-filter>
        </div>
    </nb-card-body>

    <nb-card-footer>
        <button nbButton status="basic" (click)="close()">
            <nb-icon icon="close" status="basic"></nb-icon>
            Close
        </button>
        <button nbButton status="primary" (click)="saveFormFilters()" [disabled]="!selectedFilters.length">
            <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
            Save
        </button>
    </nb-card-footer>
</nb-card>
