import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {
    AnalyzerPopoutFilter,
    AnalyzerPopoutGroup,
    AnalyzerPopoutInput,
    AnalyzerPopoutSensitivity,
    AnalyzerPopoutSettings,
    PopoutService,
} from '@core/interfaces/common/popout';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    finalize,
    mergeMap,
    shareReplay,
    startWith,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AnalyzerRequest, AnalyzerService} from '@core/interfaces/engin/analyzer';
import {StudiesStore} from '../common/studies.store';
import {UsageAnalyticsService} from '@core/utils/usage-analytics.service';
import {WorkflowInfo, WorkflowItemInfo} from '@core/interfaces/common/users';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {isEqual} from 'lodash';
import {APIResponse, Filter, FilterFieldOption, FilterFieldType} from '@core/interfaces/system/system-common';

@Injectable()
export class AnalyzerControlStore extends Unsubscribable {
    public popoutFormGroup = this.fb.group({
        filters: this.fb.group({}),
        groups: null,
        sensitivity: null,
        inputs: this.fb.group({}),
    });

    private controlPanelCollapsed = new BehaviorSubject<boolean>(true);
    readonly controlPanelCollapsed$: Observable<boolean> = this.controlPanelCollapsed.asObservable();

    private popoutFormGroupValue = new BehaviorSubject(null);
    readonly currentPopout$: Observable<AnalyzerRequest> = this.popoutFormGroupValue
        .asObservable()
        .pipe(filter((value) => value !== null));

    private popoutSettings = new BehaviorSubject<AnalyzerPopoutSettings>(null);
    readonly popoutSettings$: Observable<AnalyzerPopoutSettings> = this.popoutSettings.asObservable().pipe(
        filter((settings) => !!settings),
        tap((settings) => {
            this.popoutChangeSubscription && this.popoutChangeSubscription.unsubscribe();
            this.initPopout(settings);
        }),
        shareReplay(1),
    );

    public activeStudy: WorkflowItemInfo = null;
    private popoutChangeSubscription: Subscription;
    private preSelectedFilters: Filter[] = [];

    constructor(
        private controlPanelService: PopoutService,
        private analyzerService: AnalyzerService,
        private fb: FormBuilder,
        protected studiesStore: StudiesStore,
        private usageAnalyticsService: UsageAnalyticsService,
    ) {
        super();

        this.studiesStore.activeWorkflowRisk$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((activeWorkflowInfo: WorkflowInfo) => {
                    if (!activeWorkflowInfo) this.destroy();

                    return activeWorkflowInfo?.workflowId && activeWorkflowInfo?.workflowId !== -1;
                }),
                distinctUntilChanged(),
                mergeMap((activeWorkflowInfo: WorkflowInfo) =>
                    this.controlPanelService.getAnalyzerPopoutSettings().pipe(
                        tap((settings: AnalyzerPopoutSettings) => {
                            this.controlPanelRefresh(settings, activeWorkflowInfo);
                        }),
                    ),
                ),
            )
            .subscribe();
    }

    get filtersFormGroup(): FormGroup {
        return this.popoutFormGroup.get('filters') as FormGroup;
    }

    get inputsFormGroup(): FormGroup {
        return this.popoutFormGroup.get('inputs') as FormGroup;
    }

    get selectedSensitivityItem(): AnalyzerPopoutSensitivity {
        return this.popoutFormGroup.value.sensitivity;
    }

    private controlPanelRefresh(settings: AnalyzerPopoutSettings, activeWorkflowInfo: WorkflowInfo) {
        // Find active study from control panel, else default study
        const activeSensitivityStudy: AnalyzerPopoutSensitivity = settings?.sensitivity?.find((s) => s.selected);
        const activeStudy: WorkflowItemInfo = activeSensitivityStudy
            ? activeWorkflowInfo.workflowItemList.find((i) => i.sensitivityCode === activeSensitivityStudy.alias)
            : activeWorkflowInfo.workflowItemList.find((i) => i.defaultItem);
        this.activeStudy = activeStudy;

        settings.filters?.forEach((filter: any) => {
            if (filter.fieldType === FilterFieldType.STRING) this.getFilterOptions(activeStudy, filter).subscribe();
        });

        this.updateSettings({
            ...settings,
            sensitivity: this.updateSensitivitySection(activeWorkflowInfo.workflowItemList, settings.sensitivity),
        });
    }

    private getFilterOptions(
        workflowItemInfo: WorkflowItemInfo,
        filter: any,
        searchString?: string,
        filters?: Filter[],
    ) {
        filter.loading = true;

        return this.analyzerService
            .getFilterFieldOptions(workflowItemInfo.workflowItemId, filter.fieldKey, searchString || '', filters || [])
            .pipe(
                tap((res: APIResponse<FilterFieldOption[]>) => (filter.options = res.response)),
                finalize(() => (filter.loading = false)),
            );
    }

    private updateSensitivitySection(
        workflowStudyList: WorkflowItemInfo[],
        fullSensitivityOptions: AnalyzerPopoutSensitivity[] = [],
    ): AnalyzerPopoutSensitivity[] {
        // Analyzer popout panel "sensitivity" section must update based on the active study collection
        /*
         * Trim down the sensitivity area of the panel based on the active study collection.
         * Note: popout settings should have more or equal amount of records
         * than are applicable for any single study collection.
         * 1) Construct the settings for active version of the Analyzer Control Panel.
         * 1a) Starting with the list of studies in the active study collection, try to join each to the settings
         * and construct the final data object.
         * 1b) Extract code, name, selected data objects. If no match is found, set name = code, selected = false
         * 2) If no items are selected, set the first element to be selected = true.
         */

        // Step 1
        let newSensitivityOptions: AnalyzerPopoutSensitivity[] = workflowStudyList.map((study: WorkflowItemInfo) => {
            // Step 1a
            const matchSettings: AnalyzerPopoutSensitivity = fullSensitivityOptions.find(
                (e: AnalyzerPopoutSensitivity) => e.alias === study.sensitivityCode,
            );
            // Step 1b
            return {
                alias: matchSettings?.alias || study.sensitivityCode,
                fieldName: matchSettings?.fieldName || study.itemName,
                fieldKey: matchSettings?.fieldKey || study.workflowItemId,
                selected: matchSettings?.selected || false,
            };
        });

        // Step 2
        if (!newSensitivityOptions.some((e: AnalyzerPopoutSensitivity) => e.selected)) {
            newSensitivityOptions[0].selected = true;
        }

        return newSensitivityOptions;
    }

    public updateSettings(settings: AnalyzerPopoutSettings) {
        this.popoutSettings.next(settings);
    }

    public destroy() {
        this.popoutChangeSubscription && this.popoutChangeSubscription.unsubscribe();

        this.filtersFormGroup?.reset();
        this.popoutFormGroup.get('groups').reset();
        this.popoutFormGroup.get('sensitivity').reset();
        this.inputsFormGroup?.reset();
    }

    private mapFormGroupValue(popoutValue: any): AnalyzerRequest {
        const filterList = [];
        this.popoutSettings.value.filters.forEach((item) => {
            let options = [];

            if (item.fieldType === FilterFieldType.STRING) {
                popoutValue.filters[item.fieldKey].forEach((option) => {
                    options.push(option.key);
                });

                // Do not add filters if there are no selected values
                if (options.length > 0) {
                    filterList.push({
                        fieldKey: item.fieldKey,
                        fieldType: item.fieldType,
                        operator: item.operator,
                        values: options,
                    });
                }
            } else if (item.fieldType === FilterFieldType.DATE_RANGE) {
                let _value = popoutValue.filters[item.fieldKey],
                    min = _value?.start,
                    max = _value?.end;

                if (min || max) {
                    options = [min ?? item.min, max ?? item.max];

                    filterList.push({
                        fieldKey: item.fieldKey,
                        fieldType: item.fieldType,
                        operator: item.operator,
                        values: options,
                    });
                }
            } else if (FilterFieldType.isNumericFieldType(item.fieldType)) {
                let _value = popoutValue.filters[item.fieldKey],
                    min = _value?.min,
                    max = _value?.max;

                if (min || max) {
                    options = [min ?? item.min, max ?? item.max];

                    filterList.push({
                        fieldKey: item.fieldKey,
                        fieldType: item.fieldType,
                        operator: item.operator,
                        values: options,
                    });
                }
            }
        });

        // Groups
        const groupList = [
            {
                fieldKey: popoutValue.groups?.fieldKey,
                selected: true,
            },
        ];

        // Sensitivity
        const sensitivityList = [
            {
                alias: popoutValue.sensitivity?.alias,
                fieldKey: popoutValue.sensitivity?.fieldKey,
                selected: true,
            },
        ];

        // Inputs
        const inputList = [];
        const inputs = Object.keys(popoutValue.inputs);
        inputs.forEach((input: string) => {
            inputList.push({
                fieldKey: input,
                dataType: 'integer',
                value: popoutValue.inputs[input],
            });
        });

        return new AnalyzerRequest(filterList, groupList, sensitivityList, inputList);
    }

    private initPopout(settings: AnalyzerPopoutSettings) {
        this.initFilters(settings.filters);
        this.initGroups(settings.groups);
        this.initSensitivity(settings.sensitivity);
        this.initInputs(settings.inputs);

        this.subscribeOnChanges();
    }

    subscribeOnChanges() {
        this.popoutChangeSubscription = this.popoutFormGroup.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                startWith(this.popoutFormGroup.value),
                tap((value) => {
                    if (this.popoutFormGroupValue.value === null) {
                        this.popoutFormGroupValue.next(this.mapFormGroupValue(value));
                    }
                }),
                debounceTime(1500),
                filter((value) => !isEqual(this.popoutFormGroupValue.value, this.mapFormGroupValue(value))),
            )
            .subscribe((value) => {
                this.popoutFormGroupValue.next(this.mapFormGroupValue(value));
                this.usageAnalyticsService.logView('Analyzer Control Panel');
            });
    }

    private initFilters(initialFilters: AnalyzerPopoutFilter[]) {
        Object.keys(this.filtersFormGroup.controls).forEach((controlName) => {
            this.filtersFormGroup.removeControl(controlName);
        });

        initialFilters.forEach((item) => {
            const preselectedFilter = this.preSelectedFilters.find((filterObj) => filterObj.fieldKey === item.fieldKey);
            if (item.fieldType === FilterFieldType.STRING) {
                this.filtersFormGroup.setControl(item.fieldKey, this.fb.control(preselectedFilter?.value || []));
            } else if (FilterFieldType.isNumericFieldType(item.fieldType)) {
                this.filtersFormGroup.setControl(item.fieldKey, this.fb.control(preselectedFilter?.value || {}));
            }
        });
    }

    private initGroups(initialGroups: AnalyzerPopoutGroup[]) {
        let _selected = initialGroups.find((item) => item.selected) || null;
        this.popoutFormGroup.patchValue({groups: _selected}, {emitEvent: false});
    }

    private initSensitivity(initialSensitivity: AnalyzerPopoutSensitivity[]) {
        let _selected = initialSensitivity.find((item) => item.selected) || null;

        this.popoutFormGroup.patchValue({sensitivity: _selected}, {emitEvent: false});
    }

    private initInputs(initialInputs: AnalyzerPopoutInput[]) {
        Object.keys(this.inputsFormGroup.controls).forEach((controlName) => {
            this.inputsFormGroup.removeControl(controlName);
        });

        initialInputs.forEach((item) => {
            this.inputsFormGroup.setControl(
                item.fieldKey,
                this.fb.control(item.value, [Validators.required, Validators.max(item.max), Validators.min(item.min)]),
            );
        });
    }

    public toggleControlPanelCollapsed(collapsed?: boolean) {
        this.controlPanelCollapsed.next(collapsed || !this.controlPanelCollapsed.value);
    }

    public updateFilterOptions(filter: any, searchString?: string, filters?: Filter[]): void {
        this.preSelectedFilters = filters;
        this.getFilterOptions(this.activeStudy, filter, searchString, filters)
            .pipe(take(1))
            .subscribe((data) => {
                const newOptions = data.response;
                const newSettings = {...this.popoutSettings.value};
                newSettings.filters.find((f) => f.fieldKey === filter.fieldKey).options = newOptions;
                this.updateSettings(newSettings);
            });
    }
}
