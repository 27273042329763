<div class="m-3">
    <!-- Title -->
    <p class="labels">
        {{ field.label }}
        <span *ngIf="fieldRequired()" class="red">*</span>
    </p>

    <!-- Main content -->
    <!-- Note: This components does not support "previous" values -->

    <ng-container [ngSwitch]="viewMode">
        <ng-container *ngSwitchCase="FormViewModeEnum.EDIT_RESULTS">
            <div class="photo-container" *ngIf="mediaLoaded | async">
                <ng-container *ngFor="let file of fileList.value">
                    <nb-card class="file-upload-card" *ngIf="file.url">
                        <nb-card-body class="file-upload-body">
                            <div class="trash-button-container">
                                <button ghost status="basic" nbButton (click)="remove(file)">
                                    <nb-icon icon="trash-2" status="basic" class="trash-icon"></nb-icon>
                                </button>
                            </div>
                            <img
                                [src]="file.url"
                                [alt]="file.fileName"
                                (click)="openCarousel(file)"
                                (error)="handleImageError(file)"
                                class="photo file-upload-image"
                            />
                        </nb-card-body>
                    </nb-card>
                </ng-container>
            </div>
            <div class="mt-2">
                <button
                    fullWidth
                    nbButton
                    status="basic"
                    (click)="addContents()"
                    [ngClass]="fieldForm?.hasError('fieldIsValid') ? 'required-btn' : ''"
                >
                    <nb-icon icon="plus-outline"></nb-icon>
                    ADD
                </button>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="FormViewModeEnum.VIEW_RESULTS">
            <div class="photo-container" *ngIf="mediaLoaded | async">
                <nb-card *ngFor="let file of fileList.value" class="file-upload-card">
                    <nb-card-body class="file-upload-body">
                        <div class="trash-button-container">
                            <button ghost status="basic" nbButton (click)="remove(file)">
                                <nb-icon icon="trash-2" status="basic" class="trash-icon"></nb-icon>
                            </button>
                        </div>
                        <img
                            [src]="file.url"
                            [alt]="file.fileName"
                            (click)="openCarousel(file)"
                            class="photo file-upload-image"
                        />
                    </nb-card-body>
                </nb-card>
            </div>
            <div class="mt-2">
                <button disabled fullWidth nbButton status="basic" class="disable-btn" (click)="addContents()">
                    <nb-icon icon="plus-outline"></nb-icon>
                    ADD
                </button>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="FormViewModeEnum.VIEW_CONFIG">
            <div class="mt-2">
                <button disabled fullWidth nbButton status="basic" class="disable-btn">
                    <nb-icon icon="plus-outline"></nb-icon>
                    ADD
                </button>
            </div>
        </ng-container>
    </ng-container>
</div>
