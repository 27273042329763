<nb-card>
    <nb-card-header class="justify-content-end">
        <button (click)="close()" ghost nbButton status="basic">
            <nb-icon icon="close" status="basic"></nb-icon>
        </button>
    </nb-card-header>
    <nb-card-body>
        <div class="carousel-container">
            <div class="image-row">
                <div class="main-img-container">
                    <div class="arrows">
                        <button ghost status="basic" nbButton (click)="back()">
                            <nb-icon icon="arrow-back-outline" status="basic"></nb-icon>
                        </button>
                    </div>
                    <img [src]="mainImage.url" alt="" class="photo main-image" />
                    <div class="arrows">
                        <button ghost status="basic" nbButton (click)="forward()">
                            <nb-icon icon="arrow-forward-outline" status="basic"></nb-icon>
                        </button>
                    </div>
                </div>
                <div #thumbnailContainer class="thumbnail-container">
                    <div *ngFor="let image of thumbnailList" (click)="selectImg(image)" class="thumbnail-row">
                        <ng-container *ngIf="image.url; else emptyDiv">
                            <img
                                [src]="image.url"
                                [alt]="image.name"
                                class="photo thumbnail-image"
                                [ngClass]="mainImage.id === image.id ? 'thumbnail selected' : 'thumbnail'"
                            />
                        </ng-container>
                        <ng-template #emptyDiv>
                            <div class="photo thumbnail-image empty-box"></div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>
