<div class="form-renderer-container" *ngIf="formIsReady$ | async">
    <div class="accordion-list-container">
        <ngx-form-section
            [sections]="formSections"
            (selectionChanged)="selectionChanged($event)"
            [continueEvent]="continueEvent"
        ></ngx-form-section>
    </div>
    <div class="accordion-cells-container">
        <ngx-form-canvas
            [currentSection]="activeSection"
            [viewMode]="viewMode"
            [currentFormResults]="currentFormResults"
            [previousFormResults]="previousFormResults"
            [fieldResultForm]="fieldResultForm"
            [pageMode]="pageMode"
            [s3service]="s3service"
            (formFieldChangeEvent)="formValueChange($event)"
        ></ngx-form-canvas>
        <button nbButton fullWidth status="primary" class="mb-2" (click)="save()">
            Next
            <nb-icon icon="arrow-forward-outline"></nb-icon>
        </button>
        <div class="gray-bar"></div>
        <p
            *ngIf="{total: totalRequiredStatus.value, section: sectionRequiredStatus.value} as status"
            class="section-state"
        >
            {{ status.section.completed }}/{{ status.section.total }} Required in the Section -
            {{ status.total.completed }}/{{ status.total.total }} Total Required
        </p>
    </div>
</div>
