import {Component, Input, OnInit, Optional, ViewChild} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {
    APIResponse,
    Filter,
    FilterFieldOption,
    FilterFieldOptionRequest,
    TableFilterConfig,
} from '@core/interfaces/system/system-common';
import {AssetRegistryStore} from '@store/asset-registry/asset-registry.store';
import {BehaviorSubject, Observable} from 'rxjs';
import {take, takeUntil, tap} from 'rxjs/operators';
import {AssetRegistryService} from '@core/interfaces/asset-registry';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {FormsService} from '@core/interfaces/common/forms';

@Component({
    selector: 'ngx-form-scope-modal',
    templateUrl: './form-scope-modal.component.html',
    styleUrls: ['./form-scope-modal.component.scss'],
})
export class FormScopeModalComponent extends Unsubscribable implements OnInit {
    @Input() formId: number = null;

    scopeLoaded: boolean = false;
    isLoading: boolean = false;
    assetNumber: number = 0;

    filterParams: BehaviorSubject<Filter[]> = new BehaviorSubject([]);
    filterParams$: Observable<Filter[]> = this.filterParams.asObservable();

    genericFilters$: Observable<TableFilterConfig[]> = this.assetRegistryStore.genericFilters$;
    selectedFilters$: Observable<Filter[]> = this.assetRegistryStore.selectedFilters$.pipe(
        tap((filters: Filter[]) => {
            //needs to be saved filters values as FilterFieldOption for FilterType.MultiSelectAutocomplete
            let _filters: Filter[] = filters.map((filter: Filter) => ({
                ...filter,
                values: filter.values.map((option: FilterFieldOption) => option.key || option),
            }));

            this.filterParams.next(_filters);
        }),
    );

    selectedFilters: Filter[] = [];

    preselectedFilters$ = this.selectedFilters$.pipe(take(1));
    filtersDynamicOptions$ = this.assetRegistryStore.filtersDynamicOptions$;

    constructor(
        @Optional() private dialogRef: NbDialogRef<FormScopeModalComponent>,
        private assetRegistryStore: AssetRegistryStore,
        private assetRegistryService: AssetRegistryService,
        private formsData: FormsService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.formsData
            .getFormScope(this.formId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((resp: APIResponse<Filter[]>) => {
                const filters = resp.response as Filter[];
                this.scopeLoaded = true;
                this.assetRegistryStore.setSelectedFilters(filters?.length ? filters : []);
            });

        this.selectedFilters$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
            if (!this.scopeLoaded) {
                return;
            }
            this.selectedFilters = data;
            this.isLoading = true;

            this.assetRegistryService
                .getAssetCount(data)
                .pipe(take(1))
                .subscribe((data) => {
                    this.isLoading = false;
                    this.assetNumber = data;
                });
        });
    }

    addFilter(filters: Filter[]) {
        this.assetRegistryStore.setSelectedFilters(filters);
    }

    getOptionsDynamically(data: FilterFieldOptionRequest) {
        this.assetRegistryStore.getOptionsDynamically(data);
    }

    saveFormFilters(): void {
        this.dialogRef.close({action: 'save', data: this.selectedFilters});
        this.assetRegistryStore.setSelectedFilters([]);
        this.scopeLoaded = false;
    }

    close(): void {
        this.dialogRef.close({action: 'cancel', data: null});
        this.assetRegistryStore.setSelectedFilters([]);
        this.scopeLoaded = false;
    }
}
