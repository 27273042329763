<div class="m-3">
    <!-- Title -->
    <p class="labels">
        {{ field.label }}
        <span *ngIf="fieldRequired()" class="red">*</span>
    </p>

    <!-- Main content -->
    <ng-container *ngIf="cellType === FormCellType.PREVIOUS; else currentField">
        <p class="value">{{ getValueOrDefault("-") }}</p>
    </ng-container>

    <ng-template #currentField [ngSwitch]="viewMode">
        <ng-container *ngSwitchCase="FormViewModeEnum.EDIT_RESULTS">
            <input
                [ngClass]="fieldForm?.touched && fieldForm?.hasError('required') ? 'required' : ''"
                nbInput
                fullWidth
                type="number"
                [pattern]="pattern"
                [formControl]="fieldForm"
            />
            <ngx-validation-message
                label="Value"
                [showPattern]="fieldForm.touched && fieldForm?.hasError('pattern')"
                [showMax]="fieldForm.touched && fieldForm?.hasError('max')"
                [showMin]="fieldForm.touched && fieldForm?.hasError('min')"
            ></ngx-validation-message>
        </ng-container>

        <ng-container *ngSwitchCase="FormViewModeEnum.VIEW_RESULTS">
            <ng-container>
                <p class="value">{{ getValueOrDefault("-") }}</p>
            </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="FormViewModeEnum.VIEW_CONFIG">
            <input nbInput fullWidth class="disable-btn" />
        </ng-container>
    </ng-template>
</div>
