import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {Form, FormsService} from '@core/interfaces/common/forms';
import {FormsApi} from '@core/backend/common/api/forms.api';
import {APIResponse, Filter, FilterFieldOption} from '@core/interfaces/system/system-common';
import {FormResponseDto} from '@core/interfaces/engin/maintenace-planning/form-visualization';

@Injectable()
export class FormsServiceImpl extends FormsService {
    constructor(private api: FormsApi) {
        super();
    }

    // Form content: basic content
    public createForm(name: string, description: string): Observable<APIResponse<Form>> {
        return this.api.createForm(name, description);
    }

    public toggleFormPublish(formId: number, isPublished: boolean): Observable<APIResponse<Form>> {
        return this.api.toggleFormPublish(formId, isPublished);
    }

    public updateForm(formId: number, name: string, description: string): Observable<APIResponse<Form>> {
        return this.api.updateForm(formId, name, description);
    }

    public getInspectionFormDto(formId: number): Observable<APIResponse<FormResponseDto>> {
        return this.api.getInspectionFormDto(formId);
    }

    // Form content: scope
    public getFormScope(formId: number): Observable<APIResponse<Filter[]>> {
        return this.api.getFormScope(formId);
    }

    public setFormScope(formId: number, filters: Filter[]): Observable<APIResponse<any>> {
        return this.api.setFormScope(formId, filters);
    }

    public getFilterOptions(fieldKey: string, searchString: string): Observable<APIResponse<FilterFieldOption[]>> {
        return this.api.getFilterOptions(fieldKey, searchString);
    }

    // Form content: config
    public commitFormConfig(
        formId: number,
        isConfirmed: boolean,
        dataPrepToolId: number,
    ): Observable<APIResponse<Form>> {
        return this.api.commitFormConfig(formId, isConfirmed, dataPrepToolId);
    }

    public downloadFormConfig(formId: number): Observable<APIResponse<any>> {
        return this.api.downloadFormConfig(formId);
    }

    // Other
    getInspectionFormsDataSource(): Observable<DataSource> {
        return this.api.getInspectionFormsDataSource();
    }

    public duplicateForm(formId: number): Observable<APIResponse<any>> {
        return this.api.duplicateForm(formId);
    }

    public deleteForm(formId: number): Observable<APIResponse<any>> {
        return this.api.deleteForm(formId);
    }
}
