import {Component, ElementRef, Input, OnInit, Optional, ViewChild} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

export interface ImageList {
    // Add other properties if needed
    name: string;
    url: string;
}

@Component({
    selector: 'ngx-image-carousel-dialog',
    templateUrl: './image-carousel-dialog.component.html',
    styleUrls: ['./image-carousel-dialog.component.scss'],
})
export class ImageCarouselDialogComponent<T extends ImageList> implements OnInit {
    @ViewChild('thumbnailContainer', {static: false}) thumbnailContainer: ElementRef;

    @Input() imageList: T[];
    @Input() mainImageName: string;
    mainImage;
    private imagesPerClick = 4;
    private thumbnailImageSize = 200.5;
    thumbnailList;

    constructor(@Optional() private dialogRef: NbDialogRef<ImageCarouselDialogComponent<T>>) {}

    ngOnInit(): void {
        this.initProcess();
    }
    initProcess() {
        let position;
        const validImg = this.imageList.filter((item) => item.url);
        const imgList = this.addIdToObjects(validImg);
        this.mainImage = imgList.find((img) => img.name === this.mainImageName);

        this.thumbnailList = this.padArrayToMultipleOfFour(imgList);
        this.thumbnailList = this.thumbnailList.map((item, index) => {
            if (index % 4 === 0) {
                position = index * this.thumbnailImageSize;
            }
            return {
                ...item,
                position: position,
            };
        });
    }
    forward() {
        const index = this.findCurrentImgIndex() === this.findLastIndex() ? 0 : this.findCurrentImgIndex() + 1;
        this.mainImage = this.thumbnailList[index];
        const container = this.thumbnailContainer.nativeElement;
        container.scrollLeft = this.mainImage.position;

        if (this.mainImage.id === this.thumbnailList[0].id) {
            container.scrollLeft = 0;
        }
    }

    back() {
        const index = this.findCurrentImgIndex() === 0 ? this.findLastIndex() : this.findCurrentImgIndex() - 1;
        this.mainImage = this.thumbnailList[index];

        const container = this.thumbnailContainer.nativeElement;
        const scrollAmount = this.imagesPerClick * this.thumbnailImageSize;
        container.scrollLeft = this.mainImage.position;

        if (this.mainImage.id === this.thumbnailList[this.thumbnailList.length - 1].id) {
            const lastScrollAmount = (this.thumbnailList.length / this.imagesPerClick) * scrollAmount;
            container.scrollLeft = lastScrollAmount;
        }
    }

    private findCurrentImgIndex() {
        return this.thumbnailList.findIndex((image) => image.id === this.mainImage.id);
    }

    private findLastIndex() {
        const filteredList = this.thumbnailList.filter((item) => item.id);
        const lastImg = filteredList[filteredList.length - 1];
        return this.thumbnailList.findIndex((image) => image.id === lastImg.id);
    }

    selectImg(image) {
        this.mainImage = image;
    }
    padArrayToMultipleOfFour<T>(array: T[]): T[] {
        const remainder = array.length % 4;
        if (remainder !== 0) {
            const paddingCount = 4 - remainder;
            for (let i = 0; i < paddingCount; i++) {
                array.push({} as T);
            }
        }
        return array;
    }
    addIdToObjects(arr) {
        let id = 1;
        return arr.map((obj) => {
            // Check if the object is not empty
            if (Object.keys(obj).length !== 0) {
                return {...obj, id: id++};
            }
            return obj; // Return the empty object as it is
        });
    }
    close() {
        this.dialogRef.close();
    }
}
