<div #buttonTarget (click)="toggle()" class="select-button" [class.expanded]="isDropdownDisplayed">
    <span class="select-label">
        <span
            *ngIf="selectionModel; else placeholderTemplate"
            [nbTooltip]="filter?.fieldName + ': ' + this.selectionModel.operatorLabel + filterOperatorValuesPreview"
        >
            {{ filter.fieldName }}: {{ selectionModel.operatorLabel }}{{ filterOperatorValuesPreview }}
        </span>

        <ng-template #placeholderTemplate>{{ filter?.fieldName || filter?.fieldKey }}</ng-template>
    </span>

    <div>
        <nb-icon icon="chevron-down-outline" aria-hidden="true" [class.open]="isDropdownDisplayed"></nb-icon>

        <nb-icon
            icon="close-circle"
            class="reset"
            status="basic"
            *ngIf="selectionModel"
            (click)="$event.stopPropagation(); reset()"
        ></nb-icon>
    </div>
</div>

<nb-option-list (clickOutside)="onClickOutside($event)" *ngIf="isDropdownDisplayed" ngxClickOutside>
    <ng-container *ngFor="let option of filter.fieldOperatorOptions">
        <ng-container [ngSwitch]="option.filterType">
            <nb-option
                *ngSwitchCase="FilterType.None"
                [ngClass]="{
                    disabled: getIsOptionDisabled(option),
                    'option-selected': selectionModel && selectionModel.filterOperator === option.filterOperator
                }"
                (click)="selectNoneOption(option)"
            >
                {{ option.operatorLabel }}
            </nb-option>

            <nb-option
                *ngSwitchCase="FilterType.SingleSelect"
                [attr.operator]="option.filterOperator"
                (click)="selectOption(option)"
                [nbSpinner]="!(options$ | async)"
                [ngClass]="{
                    disabled: getIsOptionDisabled(option) || !(options$ | async)?.length,
                    'option-selected': selectionModel && selectionModel.filterOperator === option.filterOperator
                }"
                nbSpinnerSize="tiny"
            >
                <ng-container *ngIf="filter.options">
                    <nb-select
                        (selectedChange)="setSingleSelect($event)"
                        [placeholder]="option.operatorLabel"
                        [selected]="selectionModel?.value"
                        fullWidth
                        optionsPanelClass="panel"
                    >
                        <nb-select-label>
                            {{ option.operatorLabel }}
                        </nb-select-label>

                        <nb-option *ngFor="let item of filter.options" [value]="item.name">
                            {{ item.key }}
                        </nb-option>
                    </nb-select>
                </ng-container>
                <ng-container *ngIf="!filter.options">
                    <nb-select
                        (selectedChange)="setSingleSelect($event)"
                        [placeholder]="option.operatorLabel"
                        [selected]="selectionModel?.value"
                        fullWidth
                        optionsPanelClass="panel"
                    >
                        <nb-select-label>
                            {{ option.operatorLabel }}
                        </nb-select-label>

                        <nb-option *ngFor="let item of options$ ? (options$ | async) : []" [value]="item.name">
                            {{ item.key }}
                        </nb-option>
                    </nb-select>
                </ng-container>
            </nb-option>

            <nb-option
                *ngSwitchCase="FilterType.MultiSelect"
                (click)="selectOption(option)"
                [attr.operator]="option.filterOperator"
                [nbSpinner]="!(options$ | async)"
                [ngClass]="{
                    disabled: getIsOptionDisabled(option) || !(options$ | async)?.length,
                    'option-selected': selectionModel && selectionModel.filterOperator === option.filterOperator
                }"
                nbSpinnerSize="tiny"
            >
                <ng-container *ngIf="filter.options">
                    <nb-select
                        [formControl]="selectedMultiSelectControl"
                        [placeholder]="option.operatorLabel"
                        fullWidth
                        multiple
                        optionsPanelClass="panel"
                    >
                        <nb-select-label>
                            {{ option.operatorLabel }}
                        </nb-select-label>

                        <nb-option
                            (click)="$event.stopPropagation()"
                            *ngFor="let item of filter.options"
                            [value]="item.name"
                        >
                            {{ item.key }}
                        </nb-option>
                    </nb-select>
                </ng-container>
                <ng-container *ngIf="!filter.options">
                    <nb-select
                        [formControl]="selectedMultiSelectControl"
                        [placeholder]="option.operatorLabel"
                        fullWidth
                        multiple
                        optionsPanelClass="panel"
                    >
                        <nb-select-label>
                            {{ option.operatorLabel }}
                        </nb-select-label>

                        <nb-option
                            (click)="$event.stopPropagation()"
                            *ngFor="let item of options$ ? (options$ | async) : []"
                            [value]="item.name"
                        >
                            {{ item.key }}
                        </nb-option>
                    </nb-select>
                </ng-container>
            </nb-option>

            <nb-option
                *ngSwitchCase="FilterType.MultiSelectAutocomplete"
                [attr.operator]="option.filterOperator"
                (click)="selectOption(option)"
                [nbSpinner]="!(options$ | async)"
                [ngClass]="{
                    disabled: getIsOptionDisabled(option),
                    'option-selected': selectionModel && selectionModel.filterOperator === option.filterOperator
                }"
                nbSpinnerSize="tiny"
            >
                <ng-container *ngIf="filter.options">
                    <ngx-multiselect-autocomplete
                        [formControl]="selectedMultiSelectAutocompleteControl"
                        [options]="filter.options"
                        [placeholder]="option.operatorLabel"
                        [selectLabel]="option.operatorLabel"
                        [withAmount]="false"
                        [currentSearching]="searchString"
                        [withStopPropagation]="true"
                        optionsPanelClass="panel-with-autocomplete"
                    ></ngx-multiselect-autocomplete>
                </ng-container>
                <ng-container *ngIf="!filter.options">
                    <ngx-multiselect-autocomplete
                        (onSearch)="onSearch($event)"
                        [formControl]="selectedMultiSelectAutocompleteControl"
                        [options]="options$ ? (options$ | async) : []"
                        [placeholder]="option.operatorLabel"
                        [selectLabel]="option.operatorLabel"
                        [withAmount]="false"
                        [currentSearching]="searchString"
                        [withStopPropagation]="true"
                        dynamicSearch="true"
                        optionsPanelClass="panel-with-autocomplete"
                    ></ngx-multiselect-autocomplete>
                </ng-container>
            </nb-option>

            <ng-container *ngSwitchCase="FilterType.FreeText">
                <ng-container
                    *ngTemplateOutlet="optionTemplate; context: {$implicit: option, templateName: freeTextTemplate}"
                ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="FilterType.Numeric">
                <ng-container
                    *ngTemplateOutlet="optionTemplate; context: {$implicit: option, templateName: numericTemplate}"
                ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="FilterType.NumericRange">
                <ng-container
                    *ngTemplateOutlet="optionTemplate; context: {$implicit: option, templateName: numericRangeTemplate}"
                ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="FilterType.Date">
                <ng-container
                    *ngTemplateOutlet="optionTemplate; context: {$implicit: option, templateName: dateTemplate}"
                ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="FilterType.DateRange">
                <ng-container
                    *ngTemplateOutlet="optionTemplate; context: {$implicit: option, templateName: dateRangeTemplate}"
                ></ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</nb-option-list>

<ng-template #optionTemplate let-option let-name="templateName">
    <nb-option
        [attr.operator]="option.filterOperator"
        [ngClass]="{
            disabled: getIsOptionDisabled(option),
            'option-selected': selectionModel && selectionModel.filterOperator === option.filterOperator
        }"
        (click)="selectOption(option)"
        [nbPopoverPlacement]="'right'"
        [nbPopover]="name"
        [nbPopoverContext]="{option}"
        [nbPopoverTrigger]="'click'"
        [nbPopoverOffset]="1"
    >
        {{ option.operatorLabel }}

        <nb-icon class="mr-1" icon="chevron-right-outline" status="basic"></nb-icon>
    </nb-option>
</ng-template>

<ng-template #freeTextTemplate let-data>
    <div class="option-popover" (click)="$event.stopPropagation()">
        <p class="title">
            {{ data.option.operatorLabel }}
        </p>

        <input #input nbInput fullWidth [value]="selectionModel?.value || ''" (blur)="setValue(input.value)" />
    </div>
</ng-template>

<ng-template #numericTemplate let-data>
    <div class="option-popover" (click)="$event.stopPropagation()">
        <p class="title">
            {{ data.option.operatorLabel }}
        </p>

        <input
            #input
            type="number"
            ngxOnlyNumber
            nbInput
            fullWidth
            [value]="selectionModel?.value || ''"
            (blur)="setValue(+input.value || null)"
        />
    </div>
</ng-template>

<ng-template #numericRangeTemplate let-data>
    <div class="option-popover" (click)="$event.stopPropagation()">
        <p class="title">
            {{ data.option.operatorLabel }}
        </p>

        <div class="d-flex align-items-center">
            <label class="range-input">
                Min
                <input
                    #min
                    (blur)="setMinNumeric(min.value, max.value)"
                    [value]="selectionModel?.values?.[0] || 0"
                    fullWidth
                    nbInput
                    ngxOnlyNumber
                    type="number"
                />
            </label>
            <span class="m-2 pt-2">&mdash;</span>
            <label class="range-input">
                Max
                <input
                    #max
                    (blur)="setMaxNumeric(min.value, max.value)"
                    [value]="selectionModel?.values?.[1] || 0"
                    fullWidth
                    nbInput
                    ngxOnlyNumber
                    type="number"
                />
            </label>
        </div>
    </div>
</ng-template>

<ng-template #dateTemplate let-data>
    <div class="option-popover date" (click)="$event.stopPropagation()">
        <p class="title">{{ data.option.operatorLabel }}</p>

        <nb-calendar [(date)]="date" (dateChange)="setDate($event)"></nb-calendar>
    </div>
</ng-template>

<ng-template #dateRangeTemplate let-data>
    <div class="option-popover date" (click)="$event.stopPropagation()">
        <p class="title">Pick Date Range</p>

        <nb-calendar-range [(range)]="range" (rangeChange)="setRangeDate($event)"></nb-calendar-range>
    </div>
</ng-template>
