<nb-card class="edit-dialog">
    <nb-card-header>
        <p class="primary-header">Upload Document</p>
        <button (click)="cancel()" ghost nbButton status="basic">
            <nb-icon icon="close" status="basic"></nb-icon>
        </button>
    </nb-card-header>

    <nb-card-body>
        <div>
            <ngx-drop-file
                (onFileAdded)="onFileAdded($event)"
                [fileFormats]="acceptedFileTypes"
                [multiple]="false"
            ></ngx-drop-file>
            <div *ngIf="fileUploadForm.value" class="file-upload-container">
                <div class="photo-container">
                    <div *ngFor="let fileUpload of fileList.value" class="file-upload-item">
                        <nb-icon
                            class="trash-icon"
                            [icon]="getIcon(fileUpload.fileFormat).icon"
                            [pack]="getIcon(fileUpload.fileFormat).pack"
                            status="basic"
                        ></nb-icon>
                        <div class="file-details">
                            <p class="file-name">{{ fileUpload.name }}</p>
                            <p class="file-size">{{ fileUpload.size }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="btn-container">
            <button (click)="cancel()" nbButton status="basic">
                <nb-icon icon="close-circle-outline"></nb-icon>
                Cancel
            </button>

            <button (click)="save()" class="ml-3" nbButton status="primary">
                <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
                Save
            </button>
        </div>
    </nb-card-body>
</nb-card>
