import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {
    AssetHealthIndex,
    AssetSensitivityData,
    AssetsService,
    DegradationFactor,
    StudyAsset,
} from '@core/interfaces/engin/assets';
import {StudiesStore} from '../common/studies.store';
import {
    debounceTime,
    filter,
    finalize,
    map,
    share,
    shareReplay,
    startWith,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import {FormArray, FormBuilder} from '@angular/forms';
import {assetFailureModesArrProps} from '@core/utils/engin/impact-of-failure/impact-of-failure';
import {ObjectsHelper} from '@core/utils';
import {AnalyzerRequest, AnalyzerService} from '@core/interfaces/engin/analyzer';
import {WorkflowItem, WorkflowService} from '@core/interfaces/engin/workflow';
import {APIResponse, Filter} from '@core/interfaces/system/system-common';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {UsageAnalyticsService} from '@core/utils/usage-analytics.service';
import {WorkflowInfo, WorkflowItemInfo} from '@core/interfaces/common/users';
import {AnalyzerControlStore} from '@store/analyzer/analyzer-control.store';
import {DisplaySettings} from '@core/interfaces/common/pages';
import {PagesStore} from '@store/config/pages.store';

export interface AssetDetailsData {
    methodology: string;
    data: any;
}

const mapProps = [
    {key: 'relativeProbability', index: 0, rate: 100},
    {key: 'customerImpact', index: 1, rate: 1},
    {key: 'financialImpact', index: 2, rate: 1},
    {key: 'environmentalImpact', index: 3, rate: 1},
    {key: 'collateralImpact', index: 4, rate: 1},
];

@Injectable()
export class AssetDetailsStore extends Unsubscribable {
    // Page starts with no data loaded until an asset is selected
    public resultsLoading = new BehaviorSubject<boolean>(false);
    public aviodFirstLoading = new BehaviorSubject<number>(0);
    public resultsLoading$ = this.resultsLoading.asObservable();

    public sensitivityDataFormGroup = this.fb.group({
        shapeExisting: this.fb.control(null),
        shapeNew: this.fb.control(null),
        scaleExisting: this.fb.control(null),
        scaleNew: this.fb.control(null),
        impactExisting: this.fb.control(null),
        impactNew: this.fb.control(null),
        maintenanceExisting: this.fb.control(null),
        maintenanceNew: this.fb.control(null),
        intCost: this.fb.control(null),
        existingAsset: this.fb.array([]),
        newAsset: this.fb.array([]),
        degradationFactors: this.fb.array([]),
        intType: this.fb.control(null),
        customers: this.fb.control(null),
    });

    public disableSensitivity = new BehaviorSubject<boolean>(false);
    public modifiedData = new BehaviorSubject<boolean>(false);
    readonly sensitivityData$: Observable<AssetSensitivityData> = this.sensitivityDataFormGroup.valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        startWith({...this.sensitivityDataFormGroup.value}),
        debounceTime(1500),
        shareReplay(1),
    );

    private currentAssetString = new BehaviorSubject<string>(null);
    readonly currentAsset$: Observable<StudyAsset> = combineLatest<Observable<string>, Observable<number>>([
        this.currentAssetString.asObservable(),
        this.studiesStore.activeStudyIdRisk$,
    ]).pipe(
        filter(([asset, currentStudyId]) => !!asset && !!currentStudyId),
        switchMap(([asset, currentStudyId]: [string, number]) => {
            this.resultsLoading.next(true);
            return this.assetsService.getStudyAsset(currentStudyId, asset);
        }),
        tap(() => {
            this.resultsLoading.next(false);
            this.resetSensitivityData();
        }),
        shareReplay(1),
    );

    private combineAssetDetailsRequestInfo$ = combineLatest<
        Observable<StudyAsset>,
        Observable<AnalyzerRequest>,
        Observable<WorkflowInfo>
    >([
        this.currentAsset$,
        this.popoutStore.currentPopout$,
        this.studiesStore.activeWorkflowRisk$.pipe(filter((d) => !!d)),
    ]).pipe(share(), shareReplay(1));

    readonly assetDetailsData$: Observable<AssetDetailsData> = this.combineAssetDetailsRequestInfo$.pipe(
        takeUntil(this.unsubscribe$),
        tap(() => this.resultsLoading.next(true)),
        switchMap(([currentAsset, currentPopout, activeWorkflowInfo]: [StudyAsset, AnalyzerRequest, WorkflowInfo]) => {
            // Reset sensitivity data whenever core inputs change: asset, collection, study
            this.resetSensitivityData();

            const activeStudyId = this.analyzerService.getActiveStudyId(currentPopout, activeWorkflowInfo);

            return combineLatest<
                Observable<any>,
                Observable<AssetSensitivityData>,
                Observable<APIResponse<WorkflowItem>>
            >([
                this.assetsService.getAssetDetails(activeStudyId, currentAsset.assetId),
                this.sensitivityData$,
                this.workflowService.getWorkflowItemById(activeStudyId),
            ]).pipe(
                takeUntil(this.unsubscribe$),
                map(
                    ([assetData, sensitivityData, activeStudyResponse]: [
                        any,
                        AssetSensitivityData,
                        APIResponse<WorkflowItem>,
                    ]) => {
                        const studyParamList = activeStudyResponse.response
                            ? activeStudyResponse.response.itemParams
                            : null;
                        let studyMethodologyParams = 'EAC';
                        if (studyParamList) {
                            const costStreamsParam = studyParamList.filter(
                                (elem) => elem.paramKey === 'cost_streams_method',
                            );
                            if (costStreamsParam && costStreamsParam[0]) {
                                studyMethodologyParams = costStreamsParam[0].value;
                            }
                        }
                        return {
                            methodology: studyMethodologyParams,
                            data: this.applySensitivityValues(assetData, sensitivityData),
                        };
                    },
                ),
            );
        }),
        filter((assetSensitivityData) => assetSensitivityData !== null),
        tap(() => this.resultsLoading.next(false)),
        shareReplay(1),
    );

    private healthIndexDataLoading = new BehaviorSubject<boolean>(false);
    readonly healthIndexDataLoading$: Observable<boolean> = this.healthIndexDataLoading.asObservable();
    readonly assetHealthIndexData$: Observable<AssetHealthIndex> = this.combineAssetDetailsRequestInfo$.pipe(
        takeUntil(this.unsubscribe$),
        tap(() => this.healthIndexDataLoading.next(true)),
        switchMap(([currentAsset, currentPopout, activeWorkflowInfo]: [StudyAsset, AnalyzerRequest, WorkflowInfo]) => {
            const selectedStudy = currentPopout.sensitivityList.find((elem) => elem.selected);
            const matchingStudy: WorkflowItemInfo = activeWorkflowInfo.workflowItemList.find(
                (workflowItem: WorkflowItemInfo) => workflowItem.sensitivityCode === selectedStudy.alias,
            );

            return combineLatest<Observable<AssetHealthIndex>, Observable<DegradationFactor[]>>([
                // Note: do not .filter(res != null) here; toastr reporting "missing HI" needs this null to flow through
                this.assetsService.getAssetDetailsHealthIndex(matchingStudy.workflowItemId, currentAsset.assetId),
                this.degradationFactorsFormArray.valueChanges.pipe(
                    debounceTime(500),
                    startWith(this.degradationFactorsFormArray.value),
                    map((sensitivityData) => sensitivityData as DegradationFactor[]),
                ),
            ]).pipe(
                takeUntil(this.unsubscribe$),
                map(([healthIndexData, degradationFactors]: [AssetHealthIndex, DegradationFactor[]]) => {
                    if (healthIndexData) {
                        return this.applyHealthIndexSensitivityValues(healthIndexData, degradationFactors);
                    } else {
                        return null;
                    }
                }),
                tap(() => {
                    this.healthIndexDataLoading.next(false);
                    this.resultsLoading.next(false);
                }),
            );
        }),
        shareReplay(1),
    );

    private searchString = new BehaviorSubject<string>('');
    public searchStringLoading = new BehaviorSubject<boolean>(false);
    readonly searchAssets$: Observable<string[]> = combineLatest<
        Observable<string>,
        Observable<WorkflowInfo>,
        Observable<AnalyzerRequest>,
        Observable<DisplaySettings>
    >([
        this.searchString.asObservable().pipe(
            filter((str) => /^[a-zA-Z0-9_-]{2,16}$/.test(str)),
            debounceTime(500),
        ),
        this.studiesStore.activeWorkflowRisk$,
        this.popoutStore.currentPopout$,
        this.pagesStore.currentDisplay$,
    ]).pipe(
        takeUntil(this.unsubscribe$),
        tap(() => this.searchStringLoading.next(true)),
        switchMap(([searchString, activeWorkflowInfo, popoutValue, displaySettings]) => {
            const req = this.analyzerService.prepareAnalyzerRequest(popoutValue, displaySettings, activeWorkflowInfo);
            let filters: Filter[] = [];
            if (
                !displaySettings?.controlPanel?.disableEntirePanel &&
                !displaySettings?.controlPanel?.disableSectionFilter
            ) {
                filters = req.filterList;
            }
            const activeStudyId = this.analyzerService.getActiveStudyId(popoutValue, activeWorkflowInfo);

            return this.assetsService
                .searchStudyAssets(activeStudyId, searchString, filters)
                .pipe(finalize(() => this.searchStringLoading.next(false)));
        }),
    );

    constructor(
        private assetsService: AssetsService,
        private workflowService: WorkflowService,
        private analyzerService: AnalyzerService,
        private studiesStore: StudiesStore,
        private popoutStore: AnalyzerControlStore,
        private pagesStore: PagesStore,
        private fb: FormBuilder,
        private usageAnalyticsService: UsageAnalyticsService,
    ) {
        super();

        this.sensitivityDataFormGroup.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                debounceTime(1500),
                map((_) => {
                    this.aviodFirstLoading.next(this.aviodFirstLoading.getValue() + 1);
                    // ignore case when all of values are null in sensitivityDataFormGroup.
                    if (this.aviodFirstLoading.value > 1) {
                        this.usageAnalyticsService.logView('Table');
                    }
                }),
            )
            .subscribe();
    }
    get degradationFactorsFormArray(): FormArray {
        return this.sensitivityDataFormGroup.get('degradationFactors') as FormArray;
    }

    setCurrentAssetString(assetStr: string) {
        this.currentAssetString.next(assetStr);
    }

    resetSensitivityData() {
        this.sensitivityDataFormGroup.reset({});
        this.sensitivityDataFormGroup.get('newAsset').reset([]);
        this.sensitivityDataFormGroup.get('existingAsset').reset([]);
        this.degradationFactorsFormArray.clear();
        this.disableSensitivity.next(false);
        this.modifiedData.next(false);
    }

    search(assetName: string) {
        this.searchString.next(assetName);
    }

    private applyHealthIndexSensitivityValues(
        assetData: AssetHealthIndex,
        degradationFactors: DegradationFactor[],
    ): AssetHealthIndex {
        const result: AssetHealthIndex = ObjectsHelper.cloneObject(assetData);

        /*
         * Rounding is applied to values after using for calculations.
         * - Note: initDegradationFactors triggers event which immediately calls updateDegradationFactors, but
         * form values (set by initDegradationFactors) are separate from calculated outputs (set by updateDegradationFactors)
         */
        this.applyRoundingFactors(result.resultsDetail);
        if (degradationFactors.length <= 0) {
            this.initDegradationFactors(result);
        } else {
            this.updateDegradationFactors(result, degradationFactors);
        }
        return result;
    }

    private initDegradationFactors(assetData: AssetHealthIndex) {
        if (assetData && assetData.resultsDetail.length > 0) {
            assetData.resultsDetail.forEach((factor, i) => {
                this.degradationFactorsFormArray.insert(i, this.fb.group({...factor}));

                this.degradationFactorsFormArray.controls[i]
                    .get('numericScore')
                    .valueChanges.pipe(
                        startWith(this.degradationFactorsFormArray.controls[i].get('numericScore').value),
                        takeUntil(this.unsubscribe$),
                    )
                    .subscribe((value) => {
                        if (value > factor.numericScoreMax)
                            this.degradationFactorsFormArray.controls[i]
                                .get('numericScore')
                                .setValue(factor.numericScoreMax, {emitEvent: false});
                    });
            });
        }
    }

    private updateDegradationFactors(assetData: AssetHealthIndex, degradationFactors: DegradationFactor[]) {
        // Normalize methodologies will normalize per factor, rather than simply at the end. Adjust per factor.
        if (AssetDetailsStore.normalizeMethodology(assetData)) {
            degradationFactors.forEach((factor, i) => {
                const numericWeight: number = Number(factor.degWeight); // parse string back to number if needed
                assetData.resultsDetail[i] = {
                    ...factor,
                    degWeight: numericWeight,
                    numericScore: factor.numericScore, // / (factor.numericScoreMax - factor.numericScoreMin),
                    weightedScore:
                        numericWeight * (factor.numericScore - factor.numericScoreMin) / (factor.numericScoreMax  - factor.numericScoreMin),
                    weightedScoreMin: 0.0,
                    weightedScoreMax: numericWeight,
                };
            });
        } else {
            degradationFactors.forEach((factor, i) => {
                assetData.resultsDetail[i] = {
                    ...factor,
                    degWeight: Number(factor.degWeight), // parse string back to number if needed
                    weightedScore: factor.degWeight * factor.numericScore,
                    weightedScoreMin: factor.degWeight * factor.numericScoreMin,
                    weightedScoreMax: factor.degWeight * factor.numericScoreMax,
                };
            });
        }

        this.applyRoundingFactors(assetData.resultsDetail);

        // TODO: recalculate gateway factor
        // Recalculate final scoring, considerate of available/valid data requirements & gateway
        let assetScore = 0;
        let assetMinScore = 0;
        let assetMaxScore = 0;
        let numeratorAvailable = 0;
        let numeratorValid = 0;
        let denominatorAvailValid = 0;
        for (let i = 0; i < assetData.resultsDetail.length; i++) {
            const next = assetData.resultsDetail[i];
            denominatorAvailValid = denominatorAvailValid + next.degWeight;
            if (next.dataAvailable) {
                numeratorAvailable = numeratorAvailable + next.degWeight;
            }
            if (next.dataValid) {
                numeratorValid = numeratorValid + next.degWeight;
            }
            if (next.dataAvailable && next.dataValid) {
                assetScore = assetScore + next.weightedScore;
                assetMinScore = assetMinScore + next.weightedScoreMin;
                assetMaxScore = assetMaxScore + next.weightedScoreMax;
            }
        }
        assetData.resultsFinal.totalScore = assetScore;
        assetData.resultsFinal.minScore = assetMinScore;
        assetData.resultsFinal.maxScore = assetMaxScore;
        assetData.resultsFinal.availTotalScore = numeratorAvailable;
        assetData.resultsFinal.validTotalScore = numeratorValid;
        assetData.resultsFinal.availValidMaxScore = denominatorAvailValid;

        // Normalize if required
        let finalScore;
        if (assetData.resultsFinal.normalizeRequired === 'YES') {
            const newMin = 0.0;
            const newMax = 100.0;
            const newScore =
                ((newMax - newMin) / (assetMaxScore - assetMinScore)) * (assetScore - assetMinScore) + newMin;

            finalScore = newScore;
            if (assetData.resultsFinal.lowScorePoor === 'NO') {
                finalScore = newMax - newScore;
            }
        } else {
            finalScore = assetScore && assetMaxScore && (100 * assetScore) / assetMaxScore;
        }

        // Calculate final HI
        if (assetData.resultsFinal.gatewayMultiplier) {
            assetData.resultsFinal.hi = parseFloat((finalScore * assetData.resultsFinal.gatewayMultiplier).toFixed(1));
        } else {
            assetData.resultsFinal.hi = parseFloat(finalScore.toFixed(1));
        }
        assetData.resultsFinal.dataAvailability = parseFloat(
            ((100 / denominatorAvailValid) * numeratorAvailable).toFixed(1),
        );
        assetData.resultsFinal.dataValidity = parseFloat(((100 / denominatorAvailValid) * numeratorValid).toFixed(1));

        this.applyRoundingTotals(assetData);
    }

    public static normalizeMethodology(assetData: AssetHealthIndex): boolean {
        const methodology: string = assetData?.info?.methodology || 'missing';

        return methodology.toLowerCase().includes('normalize');
    }

    private applySensitivityValues(assetData: any, sensitivityData: AssetSensitivityData): any {
        const result = ObjectsHelper.cloneObject(assetData);

        // Track whether sensitivity has been applied
        result['sensitivityApplied'] = false;

        this.applySensitivityValue(result, sensitivityData, 'shapeExisting', 'shapeexisting');
        this.applySensitivityValue(result, sensitivityData, 'scaleExisting', 'scaleexisting');
        this.applySensitivityValue(result, sensitivityData, 'shapeNew', 'shapenew');
        this.applySensitivityValue(result, sensitivityData, 'scaleNew', 'scalenew');
        this.applySensitivityValue(result, sensitivityData, 'impactExisting', 'totalimpactcostexisting');
        this.applySensitivityValue(result, sensitivityData, 'maintenanceExisting', 'maintenancecostexisting');
        this.applySensitivityValue(result, sensitivityData, 'intCost', 'interventioncost');
        this.applySensitivityValue(result, sensitivityData, 'impactNew', 'totalimpactcostnew');
        this.applySensitivityValue(result, sensitivityData, 'maintenanceNew', 'maintenancecostnew');
        this.applySensitivityValue(result, sensitivityData, 'customers', 'customercount');

        this.applySensitivityValueString(result, sensitivityData, 'intType', 'interventiontype');

        if (sensitivityData.existingAsset.length <= 0) {
            this.initFailureModes(result, 'existing');
        } else {
            this.updateFailureModes(result, sensitivityData, 'existing');
        }

        if (sensitivityData.newAsset.length <= 0) {
            this.initFailureModes(result, 'new');
        } else {
            this.updateFailureModes(result, sensitivityData, 'new');
        }

        return result;
    }

    private applySensitivityValue(
        assetData: any,
        sensitivityData: AssetSensitivityData,
        prop: string,
        mapProp: string,
    ) {
        if (sensitivityData[prop]) {
            if (Math.round(assetData[mapProp]) !== Math.round(sensitivityData[prop])) {
                assetData['sensitivityApplied'] = true;
            }
            assetData[mapProp] = parseFloat(sensitivityData[prop]);
        } else {
            if (typeof assetData[mapProp] !== 'number') {
                this.sensitivityDataFormGroup.patchValue({[prop]: 0}, {emitEvent: false});
            } else {
                this.sensitivityDataFormGroup.patchValue(
                    {[prop]: parseFloat(assetData[mapProp].toFixed(2))},
                    {emitEvent: false},
                );
            }
        }
    }

    private applySensitivityValueString(
        assetData: any,
        sensitivityData: AssetSensitivityData,
        prop: string,
        mapProp: string,
    ) {
        if (sensitivityData[prop]) {
            assetData[mapProp] = sensitivityData[prop];
        } else {
            this.sensitivityDataFormGroup.patchValue({[prop]: assetData[mapProp]}, {emitEvent: false});
        }
    }

    private initFailureModes(assetData: any, asset: string) {
        const control = this.sensitivityDataFormGroup.controls[`${asset}Asset`] as FormArray;
        assetFailureModesArrProps
            .map((prop) => `${asset}${prop}`)
            .forEach((prop, i) => {
                if (assetData[prop] !== null && assetData[prop].length > 0) {
                    const group = {};
                    mapProps.forEach((mapProp) => {
                        group[mapProp.key] = assetData[prop][mapProp.index] * mapProp.rate;
                    });
                    control.insert(i, this.fb.group(group), {emitEvent: false});
                }
            });
    }

    private updateFailureModes(assetData: any, sensitivityData: AssetSensitivityData, asset: string) {
        const control = this.sensitivityDataFormGroup.get(`${asset}Asset`) as FormArray;
        assetFailureModesArrProps
            .map((prop) => `${asset}${prop}`)
            .forEach((prop, i) => {
                if (
                    assetData[prop] !== null &&
                    assetData[prop].length > 0 &&
                    assetData[prop].every((item) => item !== null)
                ) {
                    mapProps.forEach((mapProp) => {
                        this.updateFailureMode(control, assetData, sensitivityData, mapProp, asset, prop, i);
                    });
                }
            });
    }

    private updateFailureMode(
        control: FormArray,
        assetData: any,
        sensitivityData: AssetSensitivityData,
        failureMode: any, // object { key: form group prop name, index: assetData arr prop index }
        asset: string, // 'new' or 'existing'
        mapProp: string, // assetData property from API
        i: number, // assetFailureModesArrProps index
    ) {
        if (sensitivityData[`${asset}Asset`][i][failureMode.key] !== null) {
            assetData[mapProp][failureMode.index] =
                sensitivityData[`${asset}Asset`][i][failureMode.key] / failureMode.rate;
        } else {
            control.at(i).patchValue(
                {
                    [failureMode.key]: assetData[mapProp][failureMode.index] * failureMode.rate,
                },
                {emitEvent: false},
            );
        }
    }

    // For each degradation factor, round to 2 decimal places: weight, weigthed score, min score, max score
    // note: parseFloat(5.0) = 5, will keep whole numbers unmodified
    private applyRoundingFactors(factors: DegradationFactor[]) {
        factors.forEach((factor) => {
            factor.degWeight = parseFloat(factor.degWeight.toFixed(2));
            factor.weightedScore = parseFloat(factor.weightedScore.toFixed(2));
            factor.weightedScoreMin = parseFloat(factor.weightedScoreMin.toFixed(2));
            factor.weightedScoreMax = parseFloat(factor.weightedScoreMax.toFixed(2));
        });
    }

    // Round total values to 2 decimal places: min/max/total for hi, dai, dvi
    private applyRoundingTotals(assetData: AssetHealthIndex) {
        assetData.resultsFinal.totalScore = parseFloat(assetData.resultsFinal.totalScore.toFixed(2));
        assetData.resultsFinal.minScore = parseFloat(assetData.resultsFinal.minScore.toFixed(2));
        assetData.resultsFinal.maxScore = parseFloat(assetData.resultsFinal.maxScore.toFixed(2));

        assetData.resultsFinal.availTotalScore = parseFloat(assetData.resultsFinal.availTotalScore.toFixed(2));
        assetData.resultsFinal.validTotalScore = parseFloat(assetData.resultsFinal.validTotalScore.toFixed(2));
        assetData.resultsFinal.availValidMaxScore = parseFloat(assetData.resultsFinal.availValidMaxScore.toFixed(2));
    }
}
