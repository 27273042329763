import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormService} from '@theme/components/form/form.service';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {
    AssetInspectionResultDto,
    FieldType,
    FormCellType,
    FormField,
    FormViewModeType,
} from '@core/interfaces/engin/maintenace-planning/form-visualization';
import {FormFieldBaseComponent} from '@theme/components/form/cells/base/form-field-base.component';
import {BehaviorSubject, Observable} from 'rxjs';
import {S3Service} from '@core/interfaces/common/s3';

@Component({
    selector: 'ngx-form-cell',
    templateUrl: './form-cell.component.html',
    styleUrls: ['./form-cell.component.scss'],
    providers: [FormService],
})
export class FormCellComponent<T> extends FormFieldBaseComponent<T> {
    protected validate(value: T): boolean {
        throw new Error('Method not implemented.');
    }
    protected get fieldForm(): FormControl | FormArray {
        throw new Error('Method not implemented.');
    }
    protected applyValueChange(item: any): T {
        throw new Error('Method not implemented.');
    }
    protected getFormValue() {
        throw new Error('Method not implemented.');
    }
    @Input() checkValidation: Observable<boolean> = new BehaviorSubject<boolean>(false);
    @Input() viewMode: FormViewModeType = FormViewModeType.EDIT_RESULTS;
    @Input() cellType: FormCellType = FormCellType.NEW;
    @Input() fields: FormField[];
    @Input() results: AssetInspectionResultDto;
    @Output() formFieldChangeEvent = new EventEmitter();
    @Input() fieldResultForm: FormGroup;
    @Input() s3service: S3Service;

    FormCellType = FormCellType;
    FieldType = FieldType;

    constructor(public formService: FormService) {
        super();
    }

    public getHeader(): String {
        switch (this.cellType) {
            case FormCellType.NEW:
                return 'New';
            case FormCellType.CURRENT:
                return 'Current';
            case FormCellType.PREVIOUS:
                return this.results == null ? 'Previous' : 'Previous ' + this.results.completedOn;
        }
    }

    // Check if this field type is rendered, based on: field.visible and this.cellType vs. field.fieldType
    public checkRenderField(field: FormField): boolean {
        const fieldVisible = this.formService.visibleCheck(field, this.results);
        if (!fieldVisible) return false;

        /*
         * Fields which show in all cell types:
         * - Numeric
         * - Text
         * - Single select
         * - Multi select
         * - Checkbox
         * Fields which DO NOT show in PREVIOUS mode
         * - Image
         * - Video
         * - Image Video
         * - Signature
         * - Issues
         * - Table
         */

        switch (field.fieldType) {
            case FieldType.NUMERIC:
            case FieldType.TEXT:
            case FieldType.SINGLE_SELECT:
            case FieldType.MULTI_SELECT:
            case FieldType.CHECKBOX:
                return true;
            case FieldType.IMAGE:
            case FieldType.VIDEO:
            case FieldType.IMAGE_VIDEO:
            case FieldType.SIGNATURE:
            case FieldType.ISSUES:
            case FieldType.TABLE:
                return this.cellType != FormCellType.PREVIOUS;
        }
    }

    public getFieldRequired(field: FormField): boolean {
        return this.formService.requiredCheck(field, this.results);
    }
}
