import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {
    FormCellType,
    FormField,
    FormViewModeType,
    TextFieldResultDto,
} from '@core/interfaces/engin/maintenace-planning/form-visualization';
import {FormFieldBaseComponent} from '@theme/components/form/cells/base/form-field-base.component';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'ngx-form-field-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.scss', '../base/form-field-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextComponent extends FormFieldBaseComponent<TextFieldResultDto> implements OnInit {
    @Input() field: FormField;
    @Input() required: boolean;
    @Input() viewMode: FormViewModeType;
    @Input() cellType: FormCellType;
    @Input() fieldResultForm: FormGroup;
    @Input() checkValidation: Observable<boolean> = new BehaviorSubject<boolean>(false);
    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        this.genericInit();
        this.checkValidation
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((value) => !!value),
                map((_) => {
                    if (!this.fieldForm?.value && this.required) {
                        this.fieldForm?.markAsTouched();
                        this.fieldForm?.setErrors({required: true});
                    }
                    this.cd.detectChanges();
                }),
            )
            .subscribe();
    }

    public getValueOrDefault(defaultValue: string): string | number {
        return this.currentValue ? this.currentValue.value : defaultValue;
    }

    /*
     * Implement abstract methods
     */
    validate(value: TextFieldResultDto): boolean {
        if (this.required && (value.value == null || value.value.trim() == '')) {
            return false;
        }

        return true;
    }

    get fieldForm() {
        return this.fieldResultForm?.get(this.field.id + '') as FormControl;
    }

    applyValueChange(item: any): TextFieldResultDto {
        return {
            ...this.currentValue,
            value: item,
        };
    }

    getFormValue(): any {
        return this.result ? this.result.value : '';
    }

    // TODO: do we need to clean-up client provided input?
    trimInternalSpaces(str): string {
        let trimmedStr = str.trim();
        let words = trimmedStr.split(/\s+/);
        return words.join(' ');
    }
    replaceNewlines(str: string): string {
        return str.replace(/\n/g, ' n');
    }
}
